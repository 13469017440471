<template>
  <section class="tiss-container">
    <BatchHeaderContainer
      :title="batch.name"      
      :batch="batch"
      :handleInvoiceSituation="updateBatchSituation"
      :openManageGuides="openManageGuides"
      @finishBatch="batch => $emit('finishBatch', batch)"
      @openInfo="openInfoSidebar"
    />
    <CardsContainer 
      :batch="batch" 
      :openDocumentModal="openDocumentModal"
      :openDocumentListModal="openDocumentListModal"
    />
    <LotFilter @change="onChangeFilter" class="no-print" />
    <GuidesTable
      :checkable="checkable"
      :headers="headers"
      :subHeaders="subHeaders"
      :rows="rows"
      :getGuide="getGuide"
      :openHistoric="openHistoric"
      :openDocumentModal="openDocumentModal"
      :openDocumentListModal="openDocumentListModal"
      :loading="loading"
      :status="batch.status"
    />
    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney, parseMoneyToNumber } from '@/utils/moneyHelper'
import map from 'lodash/map'
import { mapState } from "vuex"
import api from '@/modules/sus/api'
import { SusBatchType }  from '@/modules/sus/manageGuides/utils/statuses'

export default {
  name: 'FinishLotsContainer',
  components: {
    CardsContainer: () => import('@/modules/sus/manageGuides/layouts/CardsContainer'),
    GuidesTable: () => import('@/components/Sus/Table/GuidesTable.vue'),
    BatchHeaderContainer: () => import('@/modules/sus/manageGuides/components/BatchHeaderContainer'),
    LotFilter: () => import('@/components/Sus/Generic/Filters/LotFilter.vue'),
  },
  props: {
    title: String,
    batch: Object,
    getGuide: Function,
    openHistoric: Function,
    openDocumentModal: Function
  },
  async created() {
    await this.getGuides()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      guides: [],


      checkable: false,
      rows: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      filters: [],
      headers: [
        'Situação',
        'Atualização',
        'Paciente',
        'N° da guia',
        'Profissional exec.',
        'Tipo de guia',
        'Faturado',
        'Recebido',
        'Ver menos'
      ],
      subHeaders: [
        'Atualização',
        'Cód. Item',
        'Descrição',
        'Valor faturado',
        'Valor pago'
      ],
      loading: false,
      cards: [],
      SusBatchType
    }
  },
  computed: {
    ...mapState({
      batches: state => state.tiss.batches,
    }),
    canFinishBatch() {
      return this.batch.status === 'Aguardando pagamento'
    },
    canWaitPayment() {
      const finishableStatus = ['Paga', 'Pag. Parcial', 'Glosada']
      return this.rows.every(row => finishableStatus.includes(row['Situação']))
    }
  },
  async mounted() {
    await this.$root.$on('update-manage-guides', async () => {
      if (this.batch?.id === this.batches?.batch?.id) {
        if (this.$store.getters["sus/getReloadContent"]) {
          await this.getGuides()
          await this.openManageGuides()
          await this.$store.dispatch('sus/reloadContent', false)
        }
      }
    })
  },
  methods: {
    parseNumberToMoney,
    parseMoneyToNumber,
    openInfoSidebar(batchId) {
      this.$emit('openInfo', batchId)
    },
    async openManageGuides() {
      const data = {
        healthPlan: this.title,
        batch: this.batch,
        cards: this.cards,
        rows: this.rows,
      }

      if (!this.reloadContent) {
        await this.$store.dispatch('sus/updateBatches', data)
      }

      this.$emit('onManageGuides', data)
    },
    changeBatchStatus(status) {
      this.batch.status = status
    },
    async updateBatchSituation(status) {
      const props = {
        status: status,
        clinic_id: this.clinic.id
      }
      const isLoading = this.$loading.show()
      try {
        await api.updateSusBatch(this.batch.id, props)
        this.changeBatchStatus(status)
        if (status === 'Finalizado' || status === 'Enviado') {
          this.$emit('onUpdate', this.batch)
        }
        this.$toast.success('Situação atualizada com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getGuides() {
      this.loading = true
      try {
        const { data } = await this.api.getSusGuideByBatchId(
          this.batch.id,
          this.currentPage ?? 1,
          this.filters
        )

        this.currentPage = data.guides?.current_page
        this.perPage = data.guides?.per_page
        this.totalItems = data.guides?.total
        this.rows = []
        
        if (data.guides?.length) {
          this.fillArray(data.guides)
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    finishBatch() {
      console.log('Finish batch ', this.batch.id);
    },
    fillArray(row) {
      let professionals = []
      row.map(guide => {
        if(guide.professional){
          professionals.push(guide.professional.name);
        }

        const guideRow = {
          id: guide.id,
          checked: false,
          files_count: guide.files_count,
          plans: guide.patient ? guide.patient.person_health_plans : null,
          'Situação': guide.situation,
          'Atualização': this.parseDate(guide.updated_at),
          'Paciente': guide?.patient?.name,
          'N° da guia': guide.guide_number,
          'Profissional exec.': professionals.length ? professionals : 'Não informado',
          'Tipo de guia': this.getTypes(guide.guide_type),
          'Faturado': this.parseNumberToMoney(guide.guide.procedures.reduce((acc, item) => {
            return acc + item.total_value
          }, 0)),
          'Recebido': this.parseNumberToMoney(guide.guide.procedures.reduce((acc, item) => {
            return acc + item.total_value
          }, 0)),
          'Detalhes': [],
          'Conta Bancaria': guide.bank_account_id,
          'Observacoes': guide.guide.observation,
          'guide_id': guide.guide.id
        }

        let items = guide.guide.procedures.map(item => ({
          id: item.id,
          Nome: item.name,
          Situação: item.status,
          Atualização: this.parseDate(item.updated_date),
          updated_date: this.moment(this.parseDate(item.updated_date), 'DD/MM/YYYY').toDate(),
          Tabela: item.table_name,
          'Cód. Item': item.item ? item.item.code : '-',
          Descrição: item?.item?.name,
          'Valor faturado': this.parseNumberToMoney(item.total_value),
          faturado: item.total_value,
          'Valor pago': this.parseNumberToMoney(item.value_paid),
          pago: item.value_paid,
          type: 'procedure',
        }))

        const expensableGuides = map(guide.guide.expensables, 'guide').filter(el => el)
        const itemsMap = map(expensableGuides, 'items')

        let expenseItems = {}

        itemsMap.forEach(items => {
          expenseItems = items.map(item => ({
            id: item.id,
            Nome: item.name,
            Situação: item.status,
            Atualização: this.moment(item.updated_date).format('DD/MM/YYYY'),
            updated_date: this.moment(this.parseDate(item.updated_date), 'DD/MM/YYYY').toDate(),
            Tabela: item.table_name,
            'Cód. Item': item.item ? item.item.code : '',
            Descrição: item.description,
            'Valor faturado': this.parseNumberToMoney(item.total_value),
            faturado: item.total_value,
            'Valor pago': this.parseNumberToMoney(item.value_paid),
            pago: item.value_paid,
            type: 'expense'
          }))
        })

        if(Object.keys(expenseItems).length) items = items.concat(expenseItems)

        const summaryGuides = map(guide.guide.hospitalization_summaries, 'guide')
        const summaryItemsMap = map(summaryGuides, 'items')
        let itemsSummary = {}

        summaryItemsMap.forEach(items => {
          itemsSummary = items.map(item => ({
            Atualização: this.moment(item.updated_date).format('DD/MM/YYYY'),
            'Cód. Item': item.item ? item.item.code : '',
            Descrição: item.description,
            'Valor faturado': this.parseNumberToMoney(item.total_value),
            'Valor pago': this.parseNumberToMoney(item.value_paid)
          }))
        })
        if(Object.keys(itemsSummary).length) items = items.concat(itemsSummary)

        guideRow.Recebido = this.parseMoneyToNumber(
          items.reduce((acc, item) => {
            return acc + item.pago
          }, 0)
        )
        guideRow.Recebido = this.parseNumberToMoney(guideRow.Recebido)

        guideRow.Detalhes = items
        this.rows.push(guideRow)
        professionals = []
      })

      this.$emit('updateGuideRows', this.rows)
    },
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getTypes(type) {
      type = type.split('\\').at(-1)
      
      const types = {
        Bpa: 'BPA',
        ApacGuide: 'APAC',
        AihGuide: 'AIH'
      }
      return types[type]
    },
    async onChangeFilter(filter) {
      this.filters = filter
      await this.getGuides()
    },
    openDocumentListModal(batch){
      this.$emit('openDocumentListModal', batch)
    },
  },
  watch: {
    batch: {
      handler: async function () {
        await this.getGuides()
      },
      deep: true
    },
    currentPage: {
      handler: async function () {
        await this.getGuides()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tiss-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin-bottom: 30px;
}
</style>
